import React from "react"
import PropTypes from "prop-types"

import PartageReseauxSociaux from "../navigation/PartageReseauxSociaux"

export default function TitrePage({ page }) {
  return (
    <div id="titre-page-conteneur">
      <h1>{page.titre}</h1>
      <div id="titre-page-conteneur-reseaux-sociaux">
        <PartageReseauxSociaux page={page} />
      </div>
    </div>
  )
}

TitrePage.propTypes = {
  page: PropTypes.object,
}
