import React from "react"
import PropTypes from "prop-types"

import SEO from "./SEO"

import "../../css/base.css"
import "../../css/layouts/site.css"
import "../../css/impression.css"
import "../../css/theme.css"
import "../../css/modules/boutons.css"
import "../../css/modules/carte-titre-et-description.css"
import "../../css/modules/carte-titre-seul.css"
import "../../css/modules/galerie-carte-presentation.css"
import "../../css/modules/galerie-image.css"
import "../../css/modules/galerie-tirage.css"
import "../../css/modules/image.css"
import "../../css/modules/liste-cartes.css"
import "../../css/modules/menu-mobile.css"
import "../../css/modules/menu-pc.css"
import "../../css/modules/partage-reseaux-sociaux.css"
import "../../css/modules/titre-page.css"
import "../../css/modules/formulaires.css"
import "../../css/modules/boutique.css"
import "../../css/modules/snipcart-custom.css"

export default function Layout(props) {
  return (
    <div id="conteneur-principal">
      <SEO page={props.page} />
      <div id="body-conteneur" className="body-conteneur-contenu">
        {props.children}
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  page: PropTypes.object,
}
