import React from "react"
import PropTypes from "prop-types"

import MenuPC from "./MenuPC"
import MenuMobile from "./MenuMobile"
import TitrePage from "../TitrePage"
import Layout from "../Layout"
import Lien from "../../navigation/Lien"

export default function LayoutFR(props) {
  return (
    <Layout page={props.page}>
      <div id="menu-pc-conteneur">
        <MenuPC page="contenu" />
      </div>
      <main id="main-conteneur">
        <MenuMobile />
        <article id="article-conteneur">
          <TitrePage page={props.page} />
          {props.children}{" "}
          {/*la propriété contenant le contenu s'appellera forcément children*/}
        </article>
        <footer id="pied-page-conteneur">
          <div>
            © Bastien Foucher - <Lien codePageCible="mentionsLegales" /> -{" "}
            <Lien codePageCible="conditionsGeneralesDeVente">CGV</Lien> -{" "}
            <Lien codePageCible="indexEN">English version</Lien>
          </div>
          <div>
            Un site web basse consommation.{" "}
            <strong>
              <Lien
                codePageCible="aPropos"
                ancre="section-numerique-responsable"
              >
                Kézako
              </Lien>
            </strong>{" "}
            ?
          </div>
        </footer>
      </main>
    </Layout>
  )
}

LayoutFR.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  page: PropTypes.object,
}
