import React from "react"
import PropTypes from "prop-types"

import Image from "../../image/Image"
import LayoutFR from "./LayoutFR"

import {
  comparerOrdreImages,
  objetEstDefini,
} from "../../../js/client-es/utils"

export default function LayoutGalerieListeImages(props) {
  const page = props.page
  const images = props.images.sort(comparerOrdreImages)

  if (!objetEstDefini(images) || images.length === 0)
    throw new Error(`Il n'y a aucune image à afficher.`)

  return (
    <LayoutFR page={page}>
      <section className="galerie-image-texte-presentation">
        <p>{page.description}</p>
      </section>
      <section id="galerie">
        {images.map(i => {
          return (
            <div
              id={i.code}
              className="galerie-image-conteneur-image"
              key={i.code}
            >
              <Image
                image={i}
                afficherDescription={false}
                classeCSSLegende="galerie-image-legende-image"
                langue={page.langue}
              />
            </div>
          )
        })}
      </section>
    </LayoutFR>
  )
}

LayoutGalerieListeImages.propTypes = {
  page: PropTypes.object,
  images: PropTypes.array.isRequired,
}
